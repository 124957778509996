(function ($) {
	var eventsFilter = {
		options: {selectorId: '#events-filter', selectorRadioClass: '.events-filter-radio-date', selectorStartDateId: '#events-filter-start-day', selectorEndDateId: '#events-filter-end-day'}, getDate: function (aDate, offset) {
			var day, month, year;
			aDate.setTime(aDate.getTime() + (offset * 1000));
			day = aDate.getDate();
			month = aDate.getMonth() + 1;
			year = aDate.getFullYear();
			if (day < 10) {
				day = '0' + day;
			}
			if (month < 10) {
				month = '0' + month;
			}
			return day + '.' + month + '.' + year;
		}, changeDate: function (startDate, endDate) {
			$(eventsFilter.options.selectorStartDateId).attr('value', startDate);
			$(eventsFilter.options.selectorEndDateId).attr('value', endDate);
		}, onChangeRadio: function () {
			var today = new Date();
			switch (this.value) {
				case'today':
					eventsFilter.changeDate(eventsFilter.getDate(today, 0), eventsFilter.getDate(today, 0));
					break;
				case'week':
					eventsFilter.changeDate(eventsFilter.getDate(today, 0), eventsFilter.getDate(today, 6 * 86400));
					break;
				case'weekend':
					if (today.getDay() > 0) {
						eventsFilter.changeDate(eventsFilter.getDate(today, (6 - today.getDay()) * 86400), eventsFilter.getDate(today, (7 - today.getDay()) * 86400));
					} else {
						eventsFilter.changeDate(eventsFilter.getDate(today, 0), eventsFilter.getDate(today, 0));
					}
					break;
				case'month':
					eventsFilter.changeDate(eventsFilter.getDate(today, 0), eventsFilter.getDate(today, 30 * 86400));
					break;
			}
		}, onKeyDownInput: function () {
			$(eventsFilter.options.selectorRadioClass).removeAttr('checked');
		}, init: function () {
			$(eventsFilter.options.selectorId).on('change', eventsFilter.options.selectorRadioClass, eventsFilter.onChangeRadio);
			$(eventsFilter.options.selectorStartDateId).on('keydown', eventsFilter.onKeyDownInput);
			$(eventsFilter.options.selectorEndDateId).on('keydown', eventsFilter.onKeyDownInput);
		}
	};
	$(document).ready(eventsFilter.init);
}(jQuery));